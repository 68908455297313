import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import Lottie from 'lottie-react';
import React, { useContext, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { SignupContext } from '../contexts/Signup';
import useResponsive from '../hooks/useResponsive';
import SelectLanguage from './SelectLanguage';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BACKGROUND = {
  width: '100%',
  height: '100%',
  bgcolor: 'white',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: { mobile: 2, desktop: 0 },
};

const FORM_CONTAINER_MOBILE = {
  bgcolor: 'white',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const INFO_CONTAINER = {
  // bgcolor: '#F9F9FF',
  width: '100%',
  height: '100vh',
  bgcolor: '#0C3954',
  // paddingLeft: 60,
  // paddingRight: 60,
};

const LOGO = {
  objectFit: 'contain',
};

const infoSteps = [
  {
    title: 'Journal by Speaking',
    description:
      'Just talk and our AI automatically turns what you say into text, letting you easily read your journal anytime',
  },
  {
    title: 'AI Generated Insights',
    description:
      'Get personalized easy-to-read insights based on the patterns, trends, and themes in your journal entries',
  },
  {
    title: 'Secure Sharing',
    description:
      'Feel confident about sharing your journal with family, friends, doctors, and more, with our data privacy controlled platform',
  },
];

const OnboardingLayout = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const [activeStep, setActiveStep] = React.useState(0);

  const { showForm, setShowForm } = useContext(SignupContext);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const addUrlParam = (paramName, paramValue) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Encode the parameter name and value
    const encodedParamName = encodeURIComponent(paramName);
    const encodedParamValue = encodeURIComponent(paramValue);

    let newUrl;

    // Check if the parameter already exists in the URL
    if (currentUrl.includes(encodedParamName + '=')) {
      // Parameter already exists, replace its value
      const regex = new RegExp('(' + encodedParamName + '=)[^&]*', 'i');
      newUrl = currentUrl.replace(regex, '$1' + encodedParamValue);
    } else {
      // Parameter doesn't exist, add it to the URL
      newUrl =
        currentUrl +
        (currentUrl.indexOf('?') !== -1 ? '&' : '?') +
        encodedParamName +
        '=' +
        encodedParamValue;
    }

    // Update the URL without reloading the page
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  useEffect(() => {
    if (showForm && !window.location.pathname.includes('reset-password')) {
      addUrlParam('showform', true);
    } else {
      addUrlParam('showform', false);
    }
  }, [showForm]);

  useEffect(() => {
    if (window.location.pathname.includes('reset-password')) {
      setShowForm(true);
    }
  }, []);

  const detailsContent = () => {
    return (
      <Container sx={INFO_CONTAINER}>
        <Box sx={{ position: 'fixed', top: 16, right: 16 }}>
          <SelectLanguage color="#fff" />
        </Box>
        <Stack
          direction={'column'}
          alignItems={'center'}
          sx={{ p: isDesktop ? 8 : 2, height: '100%', width: '100%' }}
          justifyContent="center"
          spacing={1}
        >
          <Hidden mdUp>
            <img
              alt="logo"
              src={require('../assets/images/hsLogoWhite.png')}
              height={50}
              style={LOGO}
            />
          </Hidden>
          <Hidden mdUp>
            <Typography
              sx={{
                color: 'white',
                fontSize: isDesktop ? 18 : 16,
                textAlign: 'center',
                fontWeight: 500,
              }}
            >
              AI powered health journaling
            </Typography>
          </Hidden>
          <Stack
            direction={'column'}
            alignItems={'start'}
            spacing={2}
            sx={{ maxWidth: '100%', pt: 3 }}
          >
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {infoSteps.map((step, index) => (
                <div key={step.title}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Stack
                      direction={'column'}
                      alignItems={'center'}
                      spacing={2}
                      sx={{
                        width: isDesktop ? 'auto' : 'calc(100vw - 64px)',
                      }}
                    >
                      <Lottie
                        animationData={require(`../assets/lotties/login${
                          index + 1
                        }.json`)}
                        style={{ width: '80%' }}
                        loop
                      />
                      <Typography
                        sx={{
                          color: 'white',
                          fontSize: { xs: 20, md: 24 },
                          textAlign: 'center',
                          fontWeight: 700,
                          width: '100%',
                        }}
                      >
                        {step.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'white',
                          opacity: 0.8,
                          fontSize: { xs: 18, md: 20 },
                          textAlign: 'center',
                          fontWeight: 400,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </Stack>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <div></div>
            <Stack
              direction={'row'}
              sx={{ width: '100%', marginTop: 32 }}
              spacing={2}
            >
              <Box
                onClick={() => setActiveStep(0)}
                sx={{
                  height: 14,
                  bgcolor: activeStep === 0 ? '#69B3E4' : '#69B3E420',
                  flex: 1,
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              ></Box>
              <Box
                onClick={() => setActiveStep(1)}
                sx={{
                  height: 14,
                  bgcolor: activeStep === 1 ? '#69B3E4' : '#69B3E420',
                  flex: 1,
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              ></Box>
              <Box
                onClick={() => setActiveStep(2)}
                sx={{
                  height: 14,
                  bgcolor: activeStep === 2 ? '#69B3E4' : '#69B3E420',
                  flex: 1,
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              ></Box>
              {/* <Box
                onClick={() => setActiveStep(3)}
                sx={{
                  height: 14,
                  bgcolor: activeStep === 3 ? '#69B3E4' : '#69B3E420',
                  flex: 1,
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              ></Box> */}
            </Stack>
          </Stack>
          {!isDesktop && <Box sx={{ width: '100%', height: 82 }}></Box>}
          {!isDesktop && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                p: 3,
                left: 0,
              }}
            >
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={() => setShowForm(true)}
              >
                Continue
              </Button>
            </Box>
          )}
        </Stack>
      </Container>
    );
  };

  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      {isDesktop ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sx={12} md={6} lg={6} xl={6}>
            <Container sx={FORM_CONTAINER_MOBILE} maxWidth={'xs'}>
              {children}
            </Container>
          </Grid>
          <Grid item xs={0} sx={0} md={6} lg={6} xl={6}>
            {detailsContent()}
          </Grid>
        </Grid>
      ) : (
        <Container disableGutters sx={{ height: '100%', width: '100%' }}>
          {showForm ? (
            <Container sx={FORM_CONTAINER_MOBILE} maxWidth={'xs'}>
              {children}
            </Container>
          ) : (
            <Container disableGutters sx={{ height: '100%', width: '100%' }}>
              {detailsContent()}
            </Container>
          )}
        </Container>
      )}
    </Container>
  );
};

export default OnboardingLayout;
