import { ArrowBackIosNew, ChevronRightRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import EditCustomTagsDialog from '../components/EditCustomTagsDialog';
import EditProfileDialog from '../components/EditProfileDialog';
import SelectLanguage from '../components/SelectLanguage';
import DeleteAccountDialog from '../components/dialogs/DeleteAccountDialog';
import LegalDialog from '../components/dialogs/LegalDialog';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import { ProfilesContext } from '../contexts/Profiles';
import { SignupContext } from '../contexts/Signup';

const Settings = () => {
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);
  const { role, resetContext: resetProfileContext } =
    useContext(ProfileContext);
  const {
    ownProfile,
    setCreatingProfile,
    resetContext: resetProfilesContext,
  } = useContext(ProfilesContext);
  const { resetContext: resetSignupContext } = useContext(SignupContext);
  const { resetContext: resetEventsContext } = useContext(EventsContext);

  const [openCustomTagsDialog, setOpenCustomTagsDialog] = useState(false);
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [openLegalDialog, setOpenLegalDialog] = useState(false);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);

  const resetGlobalContext = () => {
    resetProfileContext();
    resetProfilesContext();
    resetSignupContext();
    resetEventsContext();
  };

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DeleteAccountDialog
        open={openDeleteAccountDialog}
        setOpen={setOpenDeleteAccountDialog}
      />
      <LegalDialog open={openLegalDialog} setOpen={setOpenLegalDialog} />
      <EditCustomTagsDialog
        open={openCustomTagsDialog}
        setOpen={setOpenCustomTagsDialog}
      />
      <EditProfileDialog
        open={openEditProfileDialog}
        setOpen={setOpenEditProfileDialog}
      />
      <AppBar position="fixed" sx={{ height: 56, bgcolor: 'white' }}>
        <Toolbar
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              window.history.back();
            }}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
          >
            <ArrowBackIosNew />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'black',
            }}
          >
            Settings
          </Typography>
          <div style={{ marginTop: 8 }}>
            <SelectLanguage color="#000" />
          </div>
        </Toolbar>
      </AppBar>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ width: '100%', pt: 8 }}
      >
        <Stack
          direction={'column'}
          spacing={3}
          sx={{
            mt: 3,
            bgcolor: 'white',
            width: '100%',
          }}
        >
          {!ownProfile && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={2}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setCreatingProfile(true);
                navigate('/signup/step1');
              }}
            >
              <Typography sx={{ fontWeight: 500 }}>
                Create Your Profile
              </Typography>
              <ChevronRightRounded />
            </Stack>
          )}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              role === 'viewer'
                ? alert('You are only a viewer of this profile')
                : setOpenEditProfileDialog(true)
            }
          >
            <Typography sx={{ fontWeight: 500 }}>Edit Profile</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenCustomTagsDialog(true)}
          >
            <Typography sx={{ fontWeight: 500 }}>Edit Journal Tags</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenLegalDialog('privacy')}
          >
            <Typography sx={{ fontWeight: 500 }}>Privacy</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenLegalDialog('terms')}
          >
            <Typography sx={{ fontWeight: 500 }}>Terms</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenLegalDialog('hipaa')}
          >
            <Typography sx={{ fontWeight: 500 }}>HIPAA</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenLegalDialog('ccpa')}
          >
            <Typography sx={{ fontWeight: 500 }}>CCPA</Typography>
            <ChevronRightRounded />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              resetGlobalContext();
              logout();
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>Logout</Typography>
            <ChevronRightRounded />
          </Stack>
          {role === 'owner' && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={2}
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenDeleteAccountDialog(true)}
            >
              <Typography sx={{ fontWeight: 500, color: 'red' }}>
                Delete Account
              </Typography>
              <ChevronRightRounded />
            </Stack>
          )}
          {role !== 'owner' && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={2}
              sx={{ cursor: 'pointer' }}
              // onClick={() => setOpenDeleteAccountDialog(true)}
            >
              <Typography sx={{ fontWeight: 500, color: 'red' }}>
                Remove Your Profile Access
              </Typography>
              <ChevronRightRounded />
            </Stack>
          )}
          <Box>{'v1.0.2'}</Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Settings;
