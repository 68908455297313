import { EmailOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import firebase from '../Firebase';
import OnboardingLayout from '../components/OnboardingLayout';
import SelectLanguage from '../components/SelectLanguage';
import { AuthContext } from '../contexts/Auth';
import { SignupContext } from '../contexts/Signup';
import { track } from '../utils/analytics';
import { emailIsValid, passwordIsValid } from '../utils/validator';

const HAVE_ACCOUNT = {
  marginTop: 24,
  marginBottom: 8,
};

const FORM = {
  width: '100%',
};

const TERMS = {
  marginTop: 16,
  paddingLeft: 32,
  paddingRight: 32,
  fontSize: 14,
};

const ERROR_MESSAGE = {
  color: 'red',
  fontSize: 14,
  textAlign: 'start',
};

const LOGO = {
  objectFit: 'contain',
};

const Signup = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const { setOrganization: setOrganizationInSignupContext } =
    useContext(SignupContext);
  const [organization, setOrganization] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [fullName, setFullName] = useState('');

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(null);
  const [errorFullName, setErrorFullName] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [signupType, setSignupType] = useState('email');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      // clear organization on this screen
      const url = new URL(window.location);
      url.searchParams.set('organization', '');
      window.history.pushState({}, '', url);
      setOrganizationInSignupContext('');
    }
  }, [isAuthenticated]);

  const checkValidation = () => {
    setErrorEmail(null);
    setErrorFullName(null);
    setErrorPassword(null);
    setErrorRepeatPassword(null);
    let isValid = true;
    if (!emailIsValid(email)) {
      setErrorEmail('Please enter a valid email address');
      isValid = false;
    }
    if (fullName.length < 3) {
      setErrorFullName('Please enter full name');
      isValid = false;
    }
    if (!passwordIsValid(password)) {
      setErrorPassword('Password should contains at least 8 characters');
      isValid = false;
    }
    if (password !== repeatPassword) {
      setErrorRepeatPassword('Passwords are not match');
      isValid = false;
    }
    if (validatePassword() === false) {
      setErrorRepeatPassword(
        'Password should contains at least 8 characters, at least 1 capital letter, and 1 digit',
      );
      isValid = false;
    }
    return isValid;
  };

  const validatePassword = () => {
    let strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (strength < 3 || password.length < 8) {
      return false;
    }
    return true;
  };

  const onSignup = async (provider) => {
    setError(null);
    if (provider === 'google') {
      signupWithGoogle();
    } else {
      if (checkValidation()) {
        signupWithEmailPaswword();
      }
    }
  };

  const signupWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithRedirect(provider);
    track('signup_with_google_button_click');
  };

  const signupWithEmailPaswword = async () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        user.updateProfile({
          displayName: fullName,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === 'auth/email-already-in-use') {
          setError(
            'There is already an account associated with this email address. Please use a different email address.',
          );
        } else {
          setError(error.message);
        }
      });
    track('signup_with_email_and_password_button_click');
  };

  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/home' + window.location.search);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (organization && organization !== '') {
      const url = new URL(window.location);
      url.searchParams.set('organization', organization);
      window.history.pushState({}, '', url);
      setOrganizationInSignupContext(organization);
    }
  }, [organization]);

  return (
    <OnboardingLayout>
      <Box sx={{ position: 'fixed', top: 16, right: 16 }}>
        <SelectLanguage color="#000" />
      </Box>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <img
          alt="logo"
          src={require('../assets/images/coloredLogo.png')}
          height={50}
          style={LOGO}
        />
        <Typography
          sx={{
            color: 'black',
            fontSize: 32,
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          Signup
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: 22,
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          AI powered health journaling
        </Typography>
        {/* {signupType !== 'email' && <div></div>}
        {signupType !== 'email' && (
          <LoadingButton
            onClick={() => onSignup('google')}
            startIcon={
              <img
                alt="logo"
                src={require('../assets/images/googleWhiteIcon.png')}
                height={24}
                width={24}
                style={{ marginInlineEnd: 8 }}
              />
            }
            variant="primary"
            size="large"
            sx={{ bgcolor: '#69B3E3', color: 'white' }}
          >
            Signup with Google
          </LoadingButton>
        )} */}
        {signupType !== 'email' && (
          <LoadingButton
            onClick={() => setSignupType('email')}
            startIcon={
              <EmailOutlined sx={{ color: '#0C3954', width: 24, height: 24 }} />
            }
            variant="primary"
            size="large"
            sx={{ bgcolor: '#CFF5E3', color: '#0C3954' }}
          >
            Signup with Email
          </LoadingButton>
        )}
        <div></div>
        {signupType === 'email' && (
          <>
            <TextField
              label="Full name"
              fullWidth
              type={'text'}
              onChange={(e) => {
                setFullName(e.target.value);
                setErrorFullName(null);
              }}
              error={errorFullName !== null}
              helperText={errorFullName}
            />
            <TextField
              label="Email"
              fullWidth
              type={'email'}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorEmail(null);
              }}
              error={errorEmail !== null}
              helperText={errorEmail}
            />
            <TextField
              label="Password"
              fullWidth
              type={'password'}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorPassword(null);
              }}
              error={errorPassword !== null}
              helperText={errorPassword}
            />
            <TextField
              label="Repeat password"
              fullWidth
              type={'password'}
              onChange={(e) => {
                setRepeatPassword(e.target.value);
                setErrorRepeatPassword(null);
              }}
              error={errorRepeatPassword !== null}
              helperText={errorRepeatPassword}
            />
            {/* <FormControl fullWidth>
              <InputLabel id="sex-select-label">
                Are you signing up through a organization? (Optional)
              </InputLabel>
              <Select
                labelId="sex-select-label"
                id="sex-select"
                value={organization}
                label="Are you signing up through a organization? (Optional)"
                onChange={(e) => setOrganization(e.target.value)}
                sx={{ textAlign: 'start' }}
              >
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'Story Catch - paused'}>SCP Testing</MenuItem>
              </Select>
            </FormControl> */}
            <LoadingButton
              loading={loading}
              onClick={() => onSignup('email')}
              variant="contained"
              size="large"
            >
              Signup
            </LoadingButton>
            {error && <Typography sx={ERROR_MESSAGE}>{error}</Typography>}
          </>
        )}
        <Stack
          style={HAVE_ACCOUNT}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Typography sx={{ fontSize: 16 }}>
            Already have an account?
          </Typography>
          <Button onClick={() => navigate('/login')} size="medium">
            Login
          </Button>
        </Stack>
        <p style={TERMS}>
          By signing up I accept the{' '}
          <a target="_blank" href="https://knowrare.com" rel="noreferrer">
            Terms & Conditions
          </a>{' '}
          and the{' '}
          <a target="_blank" href="https://knowrare.com" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </Stack>
    </OnboardingLayout>
  );
};

export default Signup;
