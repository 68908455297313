import { LanguageRounded } from '@mui/icons-material';
import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';

export default function SelectLanguage({ color = '#000' }) {
  const [lang, setLang] = React.useState('en');

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('wglang')) {
      setLang(localStorage.getItem('wglang'));
    }
  }, []);

  const handleChange = (event) => {
    window.Weglot.switchTo(event.target.value);
    setLang(event.target.value);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <div
      style={{
        height: 42,
        display: 'flex',
        alignItems: 'center',
        marginTop: -8,
      }}
      id="weglot-ignore"
    >
      <FormControl id="weg-ex" sx={{ pl: 1 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lang}
          onChange={handleChange}
          disableUnderline
          variant="standard"
          startAdornment={<LanguageRounded sx={{ marginInlineEnd: 1 }} />}
          sx={{
            fontSize: 14,
            color,
          }}
        >
          <MenuItem
            value={'en'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            EN
          </MenuItem>
          <MenuItem
            value={'es'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            ES
          </MenuItem>
          <MenuItem
            value={'de'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            DE
          </MenuItem>
          <MenuItem
            value={'it'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            IT
          </MenuItem>
          <MenuItem
            value={'no'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            NO
          </MenuItem>
          <MenuItem
            value={'fr'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            FR
          </MenuItem>
          <MenuItem
            value={'pl'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            PL
          </MenuItem>
          <MenuItem
            value={'he'}
            sx={{ fontSize: 14 }}
            className="weglot-ignore"
            data-wg-notranslate
          >
            HE
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
