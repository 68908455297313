import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import firebase from './Firebase';

import mixpanel from 'mixpanel-browser';
import DashboardLayout from './components/DashboardLayout';
import Loader from './components/Loader';
import { AuthContext } from './contexts/Auth';
import { ProfileContext } from './contexts/Profile';
import { ProfilesContext } from './contexts/Profiles';
import AcceptInvitation from './screens/AcceptInvitation';
import AuthActions from './screens/AuthActions';
import Chat from './screens/Chat';
import CheckIn from './screens/CheckIn';
import Dashboard from './screens/Dashboard';
import Home from './screens/Home';
import Journal from './screens/Journal';
import Learn from './screens/Learn';
import Login from './screens/Login';
import Medication from './screens/Medication';
import Medications from './screens/Medications';
import Profile from './screens/Profile';
import Progress from './screens/Progress';
import ResetPassword from './screens/ResetPassword';
import Settings from './screens/Settings';
import ShareProfile from './screens/ShareProfile';
import Signup from './screens/Signup';
import SignupStep1 from './screens/SignupStep1';
import SignupStep2 from './screens/SignupStep2';
import SignupStep3 from './screens/SignupStep3';
import Test from './screens/Test';
import Timeline from './screens/Timeline';
import PrivateRoute from './utils/PrivateRoute';
import { track } from './utils/analytics';

function App() {
  const location = useLocation();
  const { isAuthenticated, setIsAuthenticated, setCurrentUser } =
    useContext(AuthContext);

  const { profiles, getProfiles } = useContext(ProfilesContext);

  const { setExpoPushToken, currentProfile } = useContext(ProfileContext);

  useEffect(() => {
    // alert(window.location.search);
    try {
      const params = new URLSearchParams(window.location.search);

      const lang = params.get('lang');
      if (lang) {
        window.Weglot.switchTo(lang);
      }

      if (params.get('utm_source') !== null) {
        localStorage.setItem('utm_source', params.get('utm_source'));
      }
      if (params.get('utm_campaign') !== null) {
        localStorage.setItem('utm_campaign', params.get('utm_campaign'));
      }
      if (params.get('utm_medium') !== null) {
        localStorage.setItem('utm_medium', params.get('utm_medium'));
      }

      const expoPushToken = params.get('expoPushToken');

      if (
        expoPushToken &&
        typeof expoPushToken === 'string' &&
        expoPushToken?.length > 0
      ) {
        // console.log('expoPushToken', params.get('expoPushToken'));
        setExpoPushToken(expoPushToken);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    onAuthChanged();
  }, []);

  const updateMixpanel = async (user) => {
    try {
      if (user != null) {
        const distinctId = await mixpanel.get_distinct_id();
        mixpanel.identify(user.uid);

        if (distinctId !== user.uid) {
          mixpanel.alias(distinctId, user.uid);
        }

        mixpanel.people.set({
          $email: user.email,
          $name: user.displayName,
          $phone: user.phoneNumber,
          $avatar: user.photoURL,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    track('app_startup');
  }, []);

  useEffect(() => {
    track('page_view', location.pathname);
  }, [location]);

  const onAuthChanged = () => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        setIsAuthenticated(true);
        getProfiles();
      } else {
        setIsAuthenticated(false);
      }
      updateMixpanel(user);
    });
    return unsubscribe;
  };

  useEffect(() => {
    if (isAuthenticated === null || (isAuthenticated && profiles === null)) {
      // alert('setCurrentProfile true');
      console.log('profiles are null');
    } else {
      console.log('profiles are not null');
    }
  }, [isAuthenticated, profiles]);

  return (
    <div className="App">
      {isAuthenticated === null || (isAuthenticated && profiles === null) ? (
        <Loader />
      ) : (
        <Routes>
          <Route path="/__/auth/action" element={<AuthActions />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/test" element={<Test />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/accept-invitation/:invitationId"
            element={<AcceptInvitation />}
          />
          <Route path="/signup/step1" element={<SignupStep1 />} />
          <Route path="/signup/step2" element={<SignupStep2 />} />
          <Route path="/signup/step3" element={<SignupStep3 />} />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute
                profiles={profiles}
                isAuthenticated={isAuthenticated}
              />
            }
          >
            <Route path="share-profile" element={<ShareProfile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="progress" element={<Progress />} />
            <Route path="medications" element={<Medications />} />
            <Route path="medications/:id" element={<Medication />} />
          </Route>
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute
                profiles={profiles}
                isAuthenticated={isAuthenticated}
              />
            }
          >
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route path="home" element={<Home />} />
              <Route path="learn" element={<Learn />} />
              <Route path="chat" element={<Chat />} />
              <Route path="journal" element={<Journal />} />
              <Route path="surveys" element={<CheckIn />} />
              <Route path="profile" element={<Profile />} />
              {/* <Route path="analytics" element={<Analytics />} /> */}
              <Route path="analytics" element={<Timeline />} />
              <Route path="*" element={<Dashboard />} />
            </Route>
          </Route>
          <Route path="/*" element={<Navigate to="/dashboard/home" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
