import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import firebase from '../Firebase';
import { SnackbarContext } from '../contexts/Snackbar';

export default function MarkMedicationAsTakenCard({ medication, index }) {
  const [medicationLogs, setMedicationLogs] = React.useState([]);
  const { show } = React.useContext(SnackbarContext);

  // Days of week starting with Monday.
  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // Determine today's index (0 = Monday, ... 6 = Sunday)
  const today = dayjs();
  const todayIndex = today.day() === 0 ? 6 : today.day() - 1;

  // Given a day index (0 for Monday, ... 6 for Sunday), get that date for this week.
  const getDateForDay = (dayIndex) => {
    // Get Monday of the current week.
    const monday = dayjs().subtract(todayIndex, 'day').startOf('day');
    return monday.add(dayIndex, 'day');
  };

  // Fetch medication logs from Firestore.
  React.useEffect(() => {
    if (medication) {
      const unsubscribe = firebase
        .firestore()
        .collection('medications')
        .doc(medication.id)
        .collection('medicationLogs')
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          setMedicationLogs(data);
        });
      return () => unsubscribe();
    }
  }, [medication]);

  // Get the medication status for a given day (0 = Monday, ... 6 = Sunday).
  // For past days, if no log exists, default to false.
  const getMedicationStatusForDay = (dayIndex) => {
    const date = getDateForDay(dayIndex);
    const logEntry = medicationLogs.find((log) => {
      const logDate = dayjs(log.date.seconds * 1000).startOf('day');
      return logDate.isSame(date, 'day');
    });
    if (date.isBefore(dayjs(), 'day')) {
      return logEntry ? logEntry.taken : false;
    }
    return logEntry ? logEntry.taken : null;
  };
  const isTimeToTakeMedication = React.useMemo(() => {
    if (!medication) return false;
    const now = dayjs();

    // Helper to convert a stored dose timestamp (in seconds) into a time for today.
    const getDoseTimeForToday = (doseTimestamp) => {
      if (!doseTimestamp) return null;
      // Convert the stored timestamp (in seconds) to a dayjs object.
      const doseTime = dayjs(doseTimestamp);
      const hour = doseTime.hour();
      const minute = doseTime.minute();
      // Build a dose time for today using the hour and minute.
      return dayjs()
        .startOf('day')
        .set('hour', hour)
        .set('minute', minute)
        .set('second', 0);
    };

    const doseTimes = [];
    const firstDose = getDoseTimeForToday(medication.data()?.firstDoseTime);
    if (firstDose) doseTimes.push(firstDose);
    const secondDose = getDoseTimeForToday(medication.data()?.secondDoseTime);
    if (secondDose) doseTimes.push(secondDose);
    const thirdDose = getDoseTimeForToday(medication.data()?.thirdDoseTime);
    if (thirdDose) doseTimes.push(thirdDose);

    // If any dose time for today is already passed, we consider it time to take medication.
    return doseTimes.some((doseTime) => now.valueOf() > doseTime.valueOf());
  }, [medication]);

  const todayStatus = getMedicationStatusForDay(todayIndex);

  // Mark medication as taken or not for a given date (default is today).
  const markMedicationAsTaken = (taken, date = dayjs()) => {
    const docId = date.format('YYYY-MM-DD');
    firebase
      .firestore()
      .collection('medications')
      .doc(medication.id)
      .collection('medicationLogs')
      .doc(docId)
      .set(
        {
          date: firebase.firestore.Timestamp.fromMillis(
            date.startOf('day').valueOf(),
          ),
          taken,
        },
        { merge: true },
      )
      .then(() => {
        show('Medication status updated successfully');
      })
      .catch((error) => console.error('Error updating document: ', error));
  };

  return (
    <Stack
      key={index}
      direction="column"
      spacing={0}
      sx={{
        width: '100%',
        borderRadius: 2,
        bgcolor: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
      }}
    >
      {/* Show reminder if today’s status is not marked and at least one dose time has passed */}
      {console.log(
        'medication',
        medication.data().medicationName,
        'todayStatus',
        todayStatus,
        'isTimeToTakeMedication',
        isTimeToTakeMedication,
      )}
      {todayStatus === null && isTimeToTakeMedication && (
        <>
          <Stack direction="column" spacing={1} sx={{ width: '100%', p: 3 }}>
            <Typography
              sx={{
                color: '#2C2C42',
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'start',
              }}
            >
              {medication.data().medicationName} Reminder
            </Typography>
            <Typography
              sx={{ color: '#2C2C42', fontSize: 14, textAlign: 'start' }}
            >
              Did you take your <b>medication</b> today?
            </Typography>
            <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  bgcolor: 'primary.main',
                  color: '#fff',
                  borderRadius: '19px',
                }}
                onClick={() => markMedicationAsTaken(true, dayjs())}
              >
                Yes
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  bgcolor: 'secondary.main',
                  color: '#2C2C42',
                  borderRadius: '19px',
                }}
                onClick={() => markMedicationAsTaken(false, dayjs())}
              >
                No
              </Button>
            </Stack>
          </Stack>
          <Divider />
        </>
      )}

      <Stack direction="column" spacing={1} sx={{ width: '100%', p: 3 }}>
        <Typography
          sx={{
            color: '#2C2C42',
            fontSize: 16,
            fontWeight: 600,
            textAlign: 'start',
          }}
        >
          {medication.data().medicationName} progress this week
        </Typography>
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
          {daysOfWeek.map((dayLabel, idx) => {
            const status = getMedicationStatusForDay(idx);
            const date = getDateForDay(idx);
            const isFuture = date.isAfter(dayjs(), 'day');

            return (
              <Stack
                key={idx}
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ flex: 1, cursor: isFuture ? 'default' : 'pointer' }}
                onClick={() => {
                  if (!isFuture) {
                    // Toggle the status: if currently true, set false; if false or null, set true.
                    markMedicationAsTaken(!(status === true), date);
                  }
                }}
              >
                <Box
                  sx={{
                    borderRadius: '50%',
                    textAlign: 'center',
                    bgcolor: isFuture
                      ? '#f5f5f5'
                      : status === true
                      ? 'primary.main'
                      : status === false
                      ? 'secondary.main'
                      : '#f5f5f5',
                    aspectRatio: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 32,
                    height: 32,
                  }}
                >
                  {!isFuture &&
                    (status === true ? (
                      <CheckRounded sx={{ width: '18px', height: '18px' }} />
                    ) : status === false ? (
                      <CloseRounded sx={{ width: '18px', height: '18px' }} />
                    ) : null)}
                </Box>
                <Typography sx={{ color: '#2C2C42', fontSize: 12 }}>
                  {dayLabel[0]}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}
