import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';
import { EVENTS } from './AddOrEditEventDialog';
import AssesmentCard from './AssesmentCard/AssesmentCard';
import EventCard from './EventCard';
import FeedCard from './FeedCard/FeedCard';

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <ArrowBackIos />
      </IconButton>
    </Box>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
}

const JournalItems = ({ events, checkin = false, selectedEvents = [] }) => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');
  const { run } = React.useContext(WalkthroughContext);

  const getFilteredEvents = () => {
    if (selectedEvents.length > 0) {
      return events?.filter(
        (e) =>
          e.data()?.format === 'new' && checkIfEventIncludesInSelectedEvents(e),
      );
    }
    return events;
  };

  const checkIfEventIncludesInSelectedEvents = (e) => {
    for (let index = 0; index < e.data()?.events.length; index++) {
      const event = e.data()?.events[index];
      if (selectedEvents.includes(getEventById(event)?.id)) {
        return true;
      }
    }
  };

  const getEventById = (id = '') => {
    return [...EVENTS, ...events].find((e) => e.id === id);
  };

  return (
    <Grid
      container
      sx={{
        width: '100%',
        background: '#FDF4F3',
        minHeight: isDesktop ? 700 : 220,
        mt: 2,
      }}
      // style={{
      //   marginTop:
      //     isDesktop && window.location.pathname.includes('home')
      //       ? -60
      //       : isDesktop && window.location.pathname.includes('surveys')
      //       ? 32
      //       : 24,
      //   paddingTop:
      //     isDesktop && window.location.pathname.includes('home') ? 72 : 16,
      // }}
      alignContent={'start'}
    >
      {run && (
        <Grid key={event.id} item sm={12} md={4} xl={4} xs={12}>
          <Box id="step-6">
            <EventCard
              event={{
                data: () => ({
                  createdBy: '2XCzYNpb5iQalwjRHE51jZrci422',
                  description:
                    'I finally had a good nights sleep after getting new medication post my hospital visit',
                  emoji: '😂',
                  events: [
                    // 'StFwCvoeOMJX6QT7l3Wc',
                    // 'YJDofemmF8kKFFeQCQFO',
                    'hZS4yw4R',
                    'jv7AyZRE',
                  ],
                  format: 'new',
                  imageUrl: null,
                  profileId: 'L3rKiySbrlNs2oddUMgW',
                  submittedAt: {
                    seconds: 1687644405,
                    nanoseconds: 0,
                  },
                  userId: '2XCzYNpb5iQalwjRHE51jZrci422',
                }),
              }}
              list
            />
          </Box>
        </Grid>
      )}
      {getFilteredEvents().map((event, _index) => (
        <Grid key={event.id} item sm={12} md={4} xl={4} xs={12}>
          <Box sx={{ pt: 1, pb: 1 }}>
            {event.data().format === 'new' ? (
              <Stack direction={'row'} spacing={4} alignItems={'center'}>
                <FeedCard event={event} list />
              </Stack>
            ) : (
              <Stack direction={'row'} spacing={4} alignItems={'center'}>
                <AssesmentCard event={event} />
              </Stack>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default JournalItems;
