import React, { createContext, useContext, useState } from 'react';
import firebase from '../Firebase';
import { track } from '../utils/analytics';
import { AuthContext } from './Auth';
import { ProfilesContext } from './Profiles';

export const SignupContext = createContext();

function SignupContextProvider(props) {
  const { currentUser } = useContext(AuthContext);
  const { getProfiles } = useContext(ProfilesContext);

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [showForm, setShowForm] = React.useState(false);

  const [name, setName] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [sex, setSex] = useState(null);
  const [age, setAge] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [healthCondition, setHealthCondition] = useState(null);
  const [whenDiagnosed, setWhenDiagnosed] = useState(null);
  const [loading, setLoading] = useState(null);
  const [forWho, setForWho] = useState(0);
  const [otherRelationship, setOtherRelationship] = useState(null);
  const [location, setLocation] = useState(null);
  const [condition, setCondition] = useState(null);
  const [conditions, setConditions] = useState(null);
  const [profiles, setProfiles] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [medications, setMedications] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [phone, setPhone] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [areasToJournal, setAreasToJournal] = useState([]);
  const [organization, setOrganization] = useState('');
  const [needHelpOrSupport, setNeedHelpOrSupport] = React.useState({
    findingTreatments: false,
    emotionalSupport: false,
    managingDailyLife: false,
    gettingDiagnosis: false,
    findingDoctorOrGeneticist: false,
    other: null,
  });
  const [showSuccessAndContactDialog, setShowSuccessAndContactDialog] =
    useState(false);

  const [afterSignup, setAfterSignup] = useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const org = params.get('organization');
    if (org) {
      // setOrganization(org);
    }
  }, []);

  const resetContext = () => {
    setName('');
    setBirthDate(null);
    setSex(null);
    setAge(null);
    setEthnicity(null);
    setHealthCondition(null);
    setWhenDiagnosed(null);
    setLoading(null);
    setForWho(0);
    setOtherRelationship(null);
    setLocation(null);
    setCondition(null);
    setConditions(null);
    setSymptoms([]);
    setMedications([]);
    setProcedures([]);
    setPhone(null);
    setRelationship(null);
    setAreasToJournal([]);
    setOrganization(null);
    setNeedHelpOrSupport({
      findingTreatments: false,
      emotionalSupport: false,
      managingDailyLife: false,
      gettingDiagnosis: false,
      findingDoctorOrGeneticist: false,
      other: null,
    });
    setShowSuccessAndContactDialog(false);
    setAfterSignup(false);
  };

  const getName = () => {
    if (forWho === 1) return name;
    return currentUser.displayName;
  };

  const createProfile = async (withFindingTreatment = false) => {
    setLoading(true);
    const profileData = {
      name: getName(),
      birthDate: firebase.firestore.Timestamp.fromMillis(birthDate.valueOf()),
      sex,
      age,
      // location,
      // ethnicity,
      condition,
      conditions,
      areasToJournal,
      // whenDiagnosed,
      relationship,
      createdAt: firebase.firestore.Timestamp.now(),
      createdBy: currentUser.uid,
      lastUpdated: firebase.firestore.Timestamp.now(),
      // needHelpOrSupport,
      forWho,
      signupCompleted: true,
      email: currentUser.email,
      // medications,
      // symptoms,
      // procedures,
      lookingForTreatments: withFindingTreatment,
      organization,
      // phone,
      utmSource: localStorage.getItem('utm_source'),
      utmMedium: localStorage.getItem('utm_medium'),
      utmCampaign: localStorage.getItem('utm_campaign'),
    };
    firebase
      .firestore()
      .collection('profiles')
      .add(profileData)
      .then((_snapshot) => {
        getProfiles();
        if (withFindingTreatment) {
          setShowSuccessAndContactDialog(true);
        }
        track('new-profile-created', profileData);
      })
      .catch((e) => console.log('e', e))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SignupContext.Provider
      value={{
        name,
        setName,
        birthDate,
        setBirthDate,
        sex,
        setSex,
        age,
        setAge,
        ethnicity,
        setEthnicity,
        healthCondition,
        setHealthCondition,
        whenDiagnosed,
        setWhenDiagnosed,
        loading,
        setLoading,
        forWho,
        setForWho,
        otherRelationship,
        setOtherRelationship,
        location,
        setLocation,
        condition,
        setCondition,
        setConditions,
        conditions,
        needHelpOrSupport,
        setNeedHelpOrSupport,
        createProfile,
        getName,
        setPhone,
        symptoms,
        setSymptoms,
        procedures,
        setProcedures,
        showSuccessAndContactDialog,
        setShowSuccessAndContactDialog,
        relationship,
        setRelationship,
        showForm,
        setShowForm,
        organization,
        setOrganization,
        afterSignup,
        setAfterSignup,
        areasToJournal,
        setAreasToJournal,
        resetContext,
      }}
    >
      {props.children}
    </SignupContext.Provider>
  );
}

export default SignupContextProvider;
