import { Box, Container } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { SignupContext } from '../contexts/Signup';
import useResponsive from '../hooks/useResponsive';
import SelectLanguage from './SelectLanguage';

const BACKGROUND = {
  width: '100%',
  height: '100%',
  bgcolor: '#F4F5FB',
  position: { sm: 'relative', md: 'fixed' },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'scroll',
};

const FORM_CONTAINER = {
  bgcolor: 'white',
  p: 4,
  borderRadius: 3,
  height: 'fit-content',
  width: 'fit-content',
  minWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 12px #00000015',
};

const FORM_CONTAINER_MOBILE = {
  bgcolor: 'white',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  pt: 3,
  pb: 3,
};

const OnboardingLayoutOld = ({ children }) => {
  const isDesktop = useResponsive('up', 'md');
  const { organization } = useContext(SignupContext);

  useEffect(() => {}, []);

  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      <Box sx={{ position: 'fixed', top: 16, right: 16 }}>
        <SelectLanguage />
      </Box>
      <Container
        sx={isDesktop ? FORM_CONTAINER : FORM_CONTAINER_MOBILE}
        maxWidth={'sm'}
      >
        {organization === 'Story Catch - paused' ? (
          <img
            alt="logo"
            src={require('../assets/images/storycatchLogoWithIcon.png')}
            height={40}
            style={{ objectFit: 'contain', marginBottom: 24 }}
          />
        ) : (
          <img
            alt="logo"
            src={require('../assets/images/coloredLogo.png')}
            height={60}
            style={{ objectFit: 'contain', marginBottom: 24 }}
          />
        )}
        {children}
      </Container>
    </Container>
  );
};

export default OnboardingLayoutOld;
