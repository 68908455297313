import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import { ProfileContext } from '../contexts/Profile';
import { SignupContext } from '../contexts/Signup';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ThemeProvider({ children, websiteData }) {
  const { organization: org1 } = useContext(ProfileContext);
  const { organization: org2 } = useContext(SignupContext);

  const themeMode = 'light';
  const themeDirection = 'ltr';

  const isLight = themeMode === 'light';

  const customPalette = useMemo(() => {
    if (org1 === 'Story Catch - paused' || org2 === 'Story Catch - paused') {
      return {
        ...palette.light,
        primary: { main: '#A980C5' },
        secondary: { main: '#ebd1ff' },
      };
    }
    return palette.light;
  }, [org1, org2]);

  const themeOptions = useMemo(
    () => ({
      palette: customPalette,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection, customPalette],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
