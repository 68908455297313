import { Card, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../../contexts/Profile';
import useResponsive from '../../hooks/useResponsive';
import { PostSurveyDialog } from '../SurveyCard';
import AddOrEditEventDialog from './../AddOrEditEventDialog';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';

const FeedCard = ({ event, list = false, type }) => {
  const isDesktop = useResponsive('up', 'md');

  const { role } = useContext(ProfileContext);

  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [openPostSurveyDialog, setOpenPostSurveyDialog] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <PostSurveyDialog
        open={openPostSurveyDialog}
        setOpen={setOpenPostSurveyDialog}
        event={event || null}
      />
      {event.data().format === 'new' && openEditEventDialog && (
        <AddOrEditEventDialog
          open={openEditEventDialog}
          setOpen={setOpenEditEventDialog}
          event={event}
        />
      )}
      <Card
        sx={{
          width: list ? '100%' : isDesktop ? 327 : '100%',
          minHeight: 180,
          p: 2,
          mr: list ? 0 : 2,
          cursor: 'pointer',
          borderRadius: 1,
          position: 'relative',
        }}
        onClick={() => {
          if (role === 'viewer') {
            // alert('You are only a viewer of this profile');
          } else {
            if (event.data().format === 'new') {
              setOpenEditEventDialog(true);
            } else {
              setOpenPostSurveyDialog(true);
            }
          }
        }}
        elevation={4}
      >
        {event.data().format === 'new' && (
          <Stack
            direction={'column'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
            style={{ height: '100%' }}
          >
            <CardHeader event={event} type={type} />
            <div style={{ flexGrow: 1 }}></div>
            <CardFooter event={event} type={type} />
          </Stack>
        )}
      </Card>
    </>
  );
};

export default FeedCard;
