import { ArrowBackIosNew } from '@mui/icons-material';
import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import AddEditMedication from '../components/AddEditMedication';
import MedicationCard from '../components/MedicationCard';
import MedicationLogs from '../components/MedicationLogs';
import { ProfileContext } from '../contexts/Profile';
import { SignupContext } from '../contexts/Signup';

const Medication = () => {
  const { medications } = React.useContext(ProfileContext);
  const { afterSignup } = React.useContext(SignupContext);
  const { id } = useParams();

  useEffect(() => {
    if (
      medications &&
      medications.length > 0 &&
      medications.find((medication) => medication.id === id)
    ) {
      setMedication(medications.find((medication) => medication.id === id));
    }
  }, [medications, id]);

  const [openAddEditMedicationDialog, setOpenAddEditMedicationDialog] =
    React.useState(false);
  const [medication, setMedication] = React.useState(null);

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {openAddEditMedicationDialog && (
        <AddEditMedication
          open={openAddEditMedicationDialog}
          setOpen={setOpenAddEditMedicationDialog}
          medication={medication}
        />
      )}
      <AppBar position="fixed" sx={{ height: 56, bgcolor: 'white' }}>
        <Toolbar>
          {!afterSignup && (
            <IconButton
              size="small"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                window.history.back();
              }}
              sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
            >
              <ArrowBackIosNew />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'black',
            }}
          >
            {medication && medication.data().medicationName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ width: '100%', pt: 8 }}
      >
        {medication && (
          <MedicationCard
            medication={medication}
            variant={'edit'}
            openEditMedicationDialog={() =>
              setOpenAddEditMedicationDialog(true)
            }
          />
        )}
        <MedicationLogs medication={medication} />
      </Stack>
    </Container>
  );
};

export default Medication;
