import {
  Box,
  Container,
  Divider,
  Slide,
  Stack,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { EVENTS } from '../components/AddOrEditEventDialog';
import AnalyticsCard from '../components/analytics/Card';
import JournalSummaryCard from '../components/analytics/JournalSummaryCard';
import CustomizedDialogs from '../components/Dialog';
import MarkMedicationAsTakenCard from '../components/MarkMedicationAsTakenCard';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import { SnackbarContext } from '../contexts/Snackbar';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';

export function ShowOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return (
    <Slide appear={false} direction="up" in={trigger}>
      {children}
    </Slide>
  );
}

export function UnhideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return trigger ? children : <></>;
}

export function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  if (trigger) {
    return <></>;
  }
  return children;
}

const Home = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { show } = useContext(SnackbarContext);
  const { showSuccessAndContactDialog, setShowSuccessAndContactDialog } =
    useContext(AuthContext);
  const { currentProfile, role, customEvents, organization, medications } =
    useContext(ProfileContext);
  const { events, setEventDialogIsOpen, openEventDialog } =
    useContext(EventsContext);
  const isDesktop = useResponsive('up', 'md');
  const [medicationMessages, setMedicationMessages] = useState([]);
  const { run } = useContext(WalkthroughContext);

  const countEventsFromStartOfMonth = () => {
    if (!events) return '-';
    // Get the current date
    const currentDate = moment();

    // Get the start of the current month
    const startOfMonth = currentDate.startOf('month');

    // Filter events created from the start of the current month
    const eventsFromStartOfMonth = events.filter((event) => {
      const eventDate = moment(event.data().submittedAt.seconds * 1000);
      return eventDate.isSameOrAfter(startOfMonth);
    });

    // Return the count of events
    return eventsFromStartOfMonth.length;
  };

  const getEventById = (id) => {
    return [...EVENTS, ...customEvents].find((e) => e.id === id);
  };

  const findMostUsedEmojis = () => {
    if (run) {
      return [
        { title: '😞', color: '#8863BA', value: 2, maxValue: 13 },
        { title: '🏥', color: '#F9DFDB', value: 5, maxValue: 13 },
        { title: '💊', color: '#B8A1D6', value: 6, maxValue: 13 },
      ];
    }
    // Step 1: Flatten the array of JSONs and extract emojis
    const allEmojis = events
      .flatMap((item) => item.data().emoji)
      .filter((item) => item !== undefined && item !== null);

    // Step 2: Count the occurrences of each emoji
    const emojiCountMap = allEmojis.reduce((countMap, emoji) => {
      countMap[emoji] = (countMap[emoji] || 0) + 1;
      return countMap;
    }, {});

    // Step 3: Convert the map to an array of { emoji, count } objects
    const emojiCountArray = Object.entries(emojiCountMap).map(
      ([emoji, count]) => ({
        emoji,
        count,
      }),
    );

    // Step 4: Sort the array by count in descending order
    emojiCountArray.sort((a, b) => b.count - a.count);

    // Step 5: Take the top 3 emojis
    const top3Emojis = emojiCountArray.slice(0, 3).map((item) => ({
      title: item.emoji,
      value: item.count,
      color: '#0C3954',
      maxValue: events.length,
    }));

    return top3Emojis;
  };

  const findMostUsedTags = () => {
    if (run) {
      return [
        { title: 'Hospital Visit', color: '#8863BA', value: 2, maxValue: 13 },
        { title: 'Mood', color: '#F9DFDB', value: 5, maxValue: 13 },
        { title: 'Medications', color: '#B8A1D6', value: 6, maxValue: 13 },
      ];
    }
    // Step 1: Flatten the array of JSONs and extract events
    const allEvents = events
      .flatMap((item) => item.data().events)
      .filter((item) => item !== undefined);

    // Step 2: Count the occurrences of each emojeventi
    const eventCountMap = allEvents.reduce((countMap, event) => {
      countMap[event] = (countMap[event] || 0) + 1;
      return countMap;
    }, {});

    // Step 3: Convert the map to an array of { event, count } objects
    const eventCountArray = Object.entries(eventCountMap).map(
      ([id, count]) => ({
        id,
        count,
      }),
    );

    // Step 4: Sort the array by count in descending order
    eventCountArray.sort((a, b) => b.count - a.count);

    // Step 5: Take the top 3 emojis
    const top3Events = eventCountArray.slice(0, 3).map((item) => ({
      title: getEventById(item.id)?.name || '',
      value: item.count,
      color: getEventById(item.id)?.color || '#0C3954',
      maxValue: allEvents.length,
    }));

    return top3Events;
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        sx={{
          pt: isDesktop ? 0 : role !== 'viewer' ? 3 : 10,
          borderRadius: 16,
        }}
      >
        {role !== 'viewer' && (
          <Typography
            sx={{
              color: 'black',
              fontSize: 20,
              fontWeight: 600,
              textAlign: 'center',
              width: '100%',
              marginTop: 72,
              // pl: 8,
              // pr: 8,
            }}
          >
            {organization === 'Story Catch - paused'
              ? `Hi ${
                  currentUser?.displayName?.split(' ')?.[0] ||
                  currentProfile?.data()?.name?.split(' ')[0]
                }, what's your story today?`
              : `Hi ${
                  currentUser?.displayName?.split(' ')?.[0] ||
                  currentProfile?.data()?.name?.split(' ')[0]
                }, what's on your mind?`}
          </Typography>
        )}
        {role !== 'viewer' && (
          <Box
            sx={{
              width: '100%',
              maxWidth: 290,
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={() => openEventDialog()}
          >
            <Lottie
              animationData={
                organization === 'Story Catch - paused'
                  ? require('../assets/lotties/circle-light-purple.json')
                  : require('../assets/lotties/addBackground.json')
              }
              loop={true}
              id="step-1"
            />
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Lottie
                animationData={
                  organization === 'Story Catch - paused'
                    ? require('../assets/lotties/add-purple.json')
                    : require('../assets/lotties/loadingBlueButton.json')
                }
                loop={true}
                width={60}
                height={60}
                style={{ width: 60, height: 60 }}
              />
            </Box>
          </Box>
        )}
        <Stack
          direction={'column'}
          gap={2}
          sx={{
            width: '100%',
            pl: 2,
            pr: 2,
            pt: 6,
            pb: 3,
            position: 'relative',
            bgcolor: '#FDF4F3',
          }}
        >
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              borderBottomLeftRadius: 24,
              borderBottomRightRadius: 24,
              bgcolor: 'white',
              height: 32,
            }}
          ></Box>
          {medications &&
            medications.length > 0 &&
            medications.map((medication, index) => (
              <MarkMedicationAsTakenCard medication={medication} key={index} />
            ))}
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Stack
              direction={'row'}
              sx={{
                bgcolor: '#CFF5E3',
                p: 1,
                borderRadius: 2,
                zIndex: 0,
                position: 'relative',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              }}
              justifyContent={'space-around'}
            >
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ mr: -5 }}
                spacing={1}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
                >
                  {countEventsFromStartOfMonth()}
                </Typography>
                <Stack direction={'column'} alignItems={'start'} sx={{}}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                  >
                    {moment().format('MMM')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: '#0C3954',
                      mt: -1,
                    }}
                  >
                    Entries
                  </Typography>
                </Stack>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ ml: -5 }}
                spacing={1}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
                >
                  {events.length}
                </Typography>
                <Stack direction={'column'} alignItems={'start'} sx={{}}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: '#0C3954',
                      mt: -1,
                    }}
                  >
                    Entries
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <JournalSummaryCard />
          <AnalyticsCard title={'Tags Used Most'} items={findMostUsedTags()} />
          <AnalyticsCard
            title={'Emojis Used Most'}
            items={findMostUsedEmojis()}
          />
        </Stack>
        {/* <JournalItems
          events={events}
          setEventDialogIsOpen={setEventDialogIsOpen}
          selectedEvents={selectedEvents}
        /> */}
        {/* <TimelineHomeWidget setEventDialogIsOpen={setEventDialogIsOpen} /> */}
      </Stack>
      <CustomizedDialogs
        title={'Thank you! Our team will get back to you soon'}
        open={showSuccessAndContactDialog}
        setOpen={setShowSuccessAndContactDialog}
      ></CustomizedDialogs>
    </Container>
  );
};

export default Home;
