import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  CloseRounded,
  Share,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import React, { useContext, useRef, useState } from 'react';
import { EventsContext } from '../../contexts/EventsContext';
import { ProfileContext } from '../../contexts/Profile';
import firebase from '../../Firebase';
import { EVENTS } from '../AddOrEditEventDialog';

const CARD = {
  p: 3,
  bgcolor: '#0C3954',
  position: 'relative',
  borderRadius: 3,
};

const STACK = {
  width: '100%',
};

const TITLE = {
  fontSize: 16,
  fontWeight: 600,
  color: 'white',
  textAlign: 'start',
};

const SUBTITLE = {
  fontSize: 16,
  color: 'white',
  textAlign: 'start',
  pr: 8,
};

const BUTTON = {
  color: 'white',
  bgcolor: '#69B3E3',
  borderRadius: 2,
};

const BG = { position: 'absolute', top: 0, right: 0, width: 100 };

const DIALOG = {
  'p': 3,
  'bgcolor': '#0C3954',
  'boxShadow': 'none',
  '.MuiDialog-paperFullScreen': {
    bgcolor: '#081c2a',
    boxShadow: 'none',
  },
};

const DIALOG_TITLE = {
  color: 'white',
  fontSize: 24,
  fontWeight: 600,
};

const DIALOG_SUBTITLE = {
  color: 'white',
  fontSize: 18,
  fontWeight: 500,
};

const DIALOG_CONTENT = {
  color: 'white',
  fontSize: 16,
};

const JournalSummaryCard = () => {
  const { currentProfile, reports, fetchReports } = useContext(ProfileContext);
  const { events } = useContext(EventsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateSummaryDialog, setOpenCreateSummaryDialog] = useState(false);
  const [openAllReportsDialog, setOpenAllReportsDialog] = useState(false);
  const [summary, setSummary] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingCreatingReport, setLoadingCreatingReport] = useState(false);
  const [reportName, setReportName] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportToShow, setReportToShow] = useState(null);
  const [dateRange, setDateRange] = useState('all');
  const [openStartDatePicker, setOpenStartDatePicker] = React.useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = React.useState(false);
  const [selectedEvents, setSelectedEvents] = React.useState([]);
  const [dots, setDots] = React.useState(1);

  React.useEffect(() => {
    if (loadingCreatingReport) {
      const intervalId = setInterval(() => {
        setDots((prevDots) => (prevDots % 3) + 1);
      }, 300);

      return () => clearInterval(intervalId);
    }
  }, [loadingCreatingReport]);

  const captureAndShareScreenshot = async (ref) => {
    if (!ref.current) return;

    try {
      // Capture screenshot
      const canvas = await html2canvas(ref.current);
      const dataUrl = canvas.toDataURL('image/png');

      // Convert Data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Use native share API if available
      if (navigator.share) {
        await navigator.share({
          files: [
            new File([blob], 'Journal-Summary.png', { type: 'image/png' }),
          ],
          title: '',
          text: '',
        });
      } else {
        alert('Sharing not supported on this device');
      }
    } catch (error) {
      console.error('Error capturing or sharing screenshot:', error);
    }
  };

  const reportRef = useRef();

  const createReport = async () => {
    setLoadingCreatingReport(true);
    let filteredEvents = events;
    if (dateRange === 'custom' && startDate && endDate) {
      filteredEvents = events.filter((e) => {
        const data = e.data();
        if (!data || !data.submittedAt || !data.submittedAt.seconds) {
          console.log('Invalid event data:', data);
          return false; // or handle the error as needed
        }
        const eventTime = dayjs(data.submittedAt.seconds * 1000).valueOf();
        return (
          eventTime >= startDate.valueOf() && eventTime <= endDate.valueOf()
        );
      });
    }
    if (dateRange === 'thisMonth' && startDate && endDate) {
      const startOfMonth = dayjs(startDate).startOf('month').valueOf();
      const endOfMonth = dayjs(endDate).endOf('month').valueOf();
      filteredEvents = events.filter((e) => {
        const data = e.data();
        if (!data || !data.submittedAt || !data.submittedAt.seconds) {
          console.log('Invalid event data:', data);
          return false; // or handle the error as needed
        }
        const eventTime = dayjs(data.submittedAt.seconds * 1000).valueOf();
        return eventTime >= startOfMonth && eventTime <= endOfMonth;
      });
    }
    if (selectedEvents.length > 0) {
      filteredEvents = filteredEvents.filter(
        (e) =>
          e.data() &&
          e.data().events &&
          e.data().events.some((id) => selectedEvents.includes(id)),
      );
    }
    if (filteredEvents.length === 0) {
      alert('Not enougth data');
      setLoadingCreatingReport(false);
      return;
    }
    setSelectedEvents([]);
    const content = filteredEvents.map(
      (e) =>
        `Report: ${e.data().description} Report events: ${e
          .data()
          .events?.join()}`,
    );
    const res = await axios.post(
      'https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/getJournalSummary',
      {
        text: `${content.join(
          ' ',
        )}. Instructions: 1. never include the event id's in the summary; 2. Dont try to format the text for example dont add **text** to make it bold`,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    const summaryContent = res.data.choices[0].message.content;

    const startOfMonth = dayjs().startOf('month').valueOf();
    const endOfMonth = dayjs().valueOf();

    let start = null;
    let end = null;

    if (dateRange === 'thisMonth') {
      start = firebase.firestore.Timestamp.fromMillis(startOfMonth);
      end = firebase.firestore.Timestamp.fromMillis(endOfMonth);
    } else if (dateRange === 'custom') {
      start = firebase.firestore.Timestamp.fromMillis(startDate.valueOf());
      end = firebase.firestore.Timestamp.fromMillis(endDate.valueOf());
    }

    const report = {
      profileId: currentProfile.id,
      createdBy: firebase.auth().currentUser.uid,
      createdAt: firebase.firestore.Timestamp.now(),
      eventTypeIds: selectedEvents,
      content: summaryContent,
      startDate: start,
      endDate: end,
      name: reportName,
    };
    const reportDoc = await firebase
      .firestore()
      .collection('reports')
      .add(report);
    fetchReports();
    setLoadingCreatingReport(false);
    setReportName('');
    setStartDate(null);
    setEndDate(null);
    const currentReport = await reportDoc.get();
    setOpenCreateSummaryDialog(false);
    setReportToShow(currentReport);
  };

  const onEventClick = (id) => {
    const array = selectedEvents;
    if (array.includes(id)) {
      const index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    setSelectedEvents([...array]);
  };

  return (
    <>
      {loadingCreatingReport && (
        <Stack
          direction={'column'}
          gap={1.5}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'white',
            zIndex: 999999999,

            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              width: 150,
              height: 50,
              fontSize: 24,
              color: 'black',
              fontWeight: 500,
            }}
          >{`Generating report${'.'.repeat(dots)}`}</Box>
          <Lottie
            animationData={require('../../assets/lotties/analyzing.json')}
            style={{
              height: 160,
              width: 160,
            }}
            loop={true}
          />
        </Stack>
      )}
      <Dialog fullScreen sx={DIALOG} open={reportToShow !== null}>
        <Stack direction={'column'} spacing={2}>
          <Stack direction={'row'} spacing={2} justifyContent={'end'}>
            <IconButton
              onClick={() => {
                captureAndShareScreenshot(reportRef);
              }}
            >
              <Share sx={{ color: 'white' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setReportToShow(null);
              }}
            >
              <CloseRounded sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
          <Stack
            direction={'column'}
            spacing={2}
            ref={reportRef}
            sx={{
              position: 'fixed',
              top: 5000,
              right: 5000,
              p: 3,
              pt: 5,
              pb: 5,
              bgcolor: '#081c2a',
              width: 380,
              height: 'fit-content',
            }}
          >
            {reportToShow?.data().name ? (
              <Typography sx={DIALOG_TITLE}>
                Journal Summary - {reportToShow?.data().name}
              </Typography>
            ) : (
              <Typography sx={DIALOG_TITLE}>Journal Summary</Typography>
            )}
            <Typography sx={DIALOG_SUBTITLE} variant="h1">
              {moment(
                events[events.length - 1]?.data().submittedAt.seconds * 1000,
              ).format('MMM YYYY ')}
              -
              {moment(events[0]?.data().submittedAt.seconds * 1000).format(
                ' MMM YYYY',
              )}
            </Typography>
            {reportToShow
              ?.data()
              .content?.split('\n')
              .map((line, index) => (
                <Typography key={index} sx={DIALOG_CONTENT}>
                  {line}
                </Typography>
              ))}
          </Stack>
          <Stack direction={'column'} spacing={2}>
            {reportToShow?.data().name ? (
              <Typography sx={DIALOG_TITLE}>
                Journal Summary - {reportToShow?.data().name}
              </Typography>
            ) : (
              <Typography sx={DIALOG_TITLE}>Journal Summary</Typography>
            )}{' '}
            <Typography sx={DIALOG_SUBTITLE} variant="h1">
              {reportToShow?.data()?.startDate
                ? moment(
                    reportToShow?.data()?.startDate?.seconds * 1000,
                  ).format('MMM DD, YYYY')
                : moment(
                    events[events.length - 1]?.data().submittedAt.seconds *
                      1000,
                  ).format('MMM DD, YYYY')}
              -
              {reportToShow?.data()?.startDate
                ? moment(reportToShow?.data()?.endDate?.seconds * 1000).format(
                    'MMM DD, YYYY',
                  )
                : moment(events[0]?.data().submittedAt.seconds * 1000).format(
                    'MMM DD, YYYY',
                  )}
            </Typography>
            {reportToShow
              ?.data()
              .content?.split('\n')
              .map((line, index) => (
                <Typography key={index} sx={DIALOG_CONTENT}>
                  {line}
                </Typography>
              ))}
          </Stack>
        </Stack>
      </Dialog>
      <Dialog fullScreen open={openCreateSummaryDialog}>
        <Stack direction={'column'} spacing={2} sx={{ p: 3, height: '100%' }}>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <IconButton
              onClick={() => {
                setOpenCreateSummaryDialog(false);
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
            <Typography sx={{ fontWeight: 600, fontSize: 20 }}>
              Create Report
            </Typography>
            <Box sx={{ width: 40, height: 40 }}></Box>
          </Stack>
          <Stack
            direction={'column'}
            spacing={1}
            sx={{ pt: 2, height: '100%', flex: 1 }}
          >
            <Typography>Report name (optional)</Typography>
            <TextField
              size="small"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
            <Box></Box>
            <Typography>Choose date range</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              disableUnderline
              variant="outlined"
              size="small"
              sx={{
                fontSize: 14,
              }}
            >
              <MenuItem value={'all'} sx={{ fontSize: 14 }} data-wg-notranslate>
                All time
              </MenuItem>
              <MenuItem
                value={'thisMonth'}
                sx={{ fontSize: 14 }}
                data-wg-notranslate
              >
                This month
              </MenuItem>
              <MenuItem
                value={'custom'}
                sx={{ fontSize: 14 }}
                data-wg-notranslate
              >
                Choose dates
              </MenuItem>
            </Select>
            {dateRange === 'custom' && (
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <DesktopDatePicker
                  onAccept={(v) => setStartDate(dayjs(v.$d))}
                  open={openStartDatePicker}
                  onOpen={() => setOpenStartDatePicker(true)}
                  onClose={() => setOpenStartDatePicker(false)}
                  disableFuture
                  slotProps={{
                    textField: {
                      onClick: () => setOpenStartDatePicker(true),
                      variant: 'outlined',
                      size: 'small',
                    },
                  }}
                />
                <Box>-</Box>
                <DesktopDatePicker
                  onAccept={(v) => setEndDate(dayjs(v.$d))}
                  open={openEndDatePicker}
                  onOpen={() => setOpenEndDatePicker(true)}
                  onClose={() => setOpenEndDatePicker(false)}
                  disableFuture
                  slotProps={{
                    textField: {
                      onClick: () => setOpenEndDatePicker(true),
                      variant: 'outlined',
                      size: 'small',
                    },
                  }}
                />
              </Stack>
            )}
            <div></div>
            <Typography>Choose specific tags (optional)</Typography>
            <Stack
              spacing={0}
              rowGap={1.5}
              direction={'row'}
              sx={{ flexWrap: 'wrap' }}
              justifyContent={'start'}
            >
              {EVENTS.filter(
                (e) =>
                  ![
                    'appointments',
                    'triggers',
                    'glimmers',
                    'myCheckIn',
                  ].includes(e.type),
              ).map((event, _index) => (
                <Button
                  variant="contained"
                  onClick={(e) => {
                    onEventClick(event.id);
                    e.target.blur();
                  }}
                  sx={{
                    'bgcolor': selectedEvents.includes(event.id)
                      ? event.color
                      : 'transparent',
                    'border': `2px solid ${event.color}`,
                    'boxShadow': 'none',
                    'color': selectedEvents.includes(event.id)
                      ? event.textColor
                      : 'black',
                    'borderRadius': 5,
                    'mr': 1.5,
                    ':hover': {
                      bgcolor: selectedEvents.includes(event.id)
                        ? event.color
                        : 'transparent',
                      color: selectedEvents.includes(event.id)
                        ? event.textColor
                        : 'black',
                    },
                  }}
                  key={event.id}
                >
                  {event.name}
                </Button>
              ))}
            </Stack>
            <Box sx={{ flex: 1 }}></Box>
            <LoadingButton
              onClick={() => createReport()}
              sx={{ borderRadius: 5 }}
              size="large"
              variant="contained"
              loading={loadingCreatingReport}
            >
              Create Report
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
      <Dialog fullScreen open={openAllReportsDialog} sx={DIALOG}>
        <Stack direction={'column'} spacing={2} sx={{ height: '100%' }}>
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <IconButton
              onClick={() => {
                setOpenAllReportsDialog(false);
              }}
            >
              <ArrowBackIosNew sx={{ color: 'white' }} />
            </IconButton>
            <Typography sx={{ fontWeight: 600, fontSize: 20, color: 'white' }}>
              All Reports
            </Typography>
            <Box sx={{ width: 40, height: 40 }}></Box>
          </Stack>
          <Stack
            direction={'column'}
            spacing={2}
            sx={{ pt: 2, height: '100%', flex: 1 }}
          >
            {reports.length === 0 && (
              <Box
                sx={{
                  flex: 1,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ color: 'white', fontSize: 18 }}>
                  No reports created yet
                </Typography>
              </Box>
            )}
            {reports
              .sort((a, b) => {
                const aSeconds = a.data().createdAt?.seconds ?? 0;
                const bSeconds = b.data().createdAt?.seconds ?? 0;
                return bSeconds - aSeconds;
              })
              .map((report, i) => (
                <Box
                  key={i}
                  onClick={() => setReportToShow(report)}
                  sx={{ borderRadius: 2, bgcolor: 'white' }}
                >
                  <Stack
                    direction={'row'}
                    sx={{ width: '100%', p: 3 }}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Stack direction={'column'}>
                      <Typography sx={{ fontWeight: '600', color: '#0C3A53' }}>
                        {report.data().name
                          ? report.data().name
                          : `Summary #${i + 1}`}
                      </Typography>
                      <Typography>
                        {dayjs(report.data().createdAt.seconds * 1000).format(
                          'MMMM DD, YYYY',
                        )}
                      </Typography>
                    </Stack>
                    <ArrowForwardIos />
                  </Stack>
                </Box>
              ))}
            <Box sx={{ flex: 1 }}></Box>
            <LoadingButton
              onClick={() => setOpenCreateSummaryDialog(true)}
              sx={{ borderRadius: 5 }}
              size="large"
              variant="contained"
              startIcon={<Add />}
            >
              Create Report
            </LoadingButton>
          </Stack>
        </Stack>
      </Dialog>
      <Card sx={CARD}>
        <img
          alt=""
          src={require('../../assets/images/journalImageBackground.png')}
          style={BG}
        />
        <Stack spacing={3} sx={STACK}>
          <Typography sx={TITLE}>Journal Summary</Typography>
          <Typography sx={SUBTITLE}>
            See your personalized journal summary that’s easy to read,
            understand and share.
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={() => setOpenCreateSummaryDialog(true)}
              loading={loadingSummary}
              startIcon={<Add />}
              sx={{ flex: 1, borderRadius: 4, fontSize: 14 }}
            >
              Create new
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="large"
              loading={loadingSummary}
              onClick={() => setOpenAllReportsDialog(true)}
              sx={{
                flex: 1,
                borderRadius: 4,
                bgcolor: '#85E7B8',
                color: '#0C3A53',
                fontSize: 14,
              }}
            >
              View Reports
            </LoadingButton>
          </Stack>
        </Stack>
        {loadingSummary && (
          <Stack
            direction={'column'}
            gap={1.5}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              background: 'white',
              zIndex: 999999999,
              top: 0,
              left: 0,
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'black',
              }}
              onClick={() => {
                setLoadingSummary(false);
                setOpenDialog(false);
              }}
            >
              <Close style={{ width: 24, height: 24 }} />
            </IconButton>
            <Box
              sx={{
                width: 150,
                height: 50,
                fontSize: 24,
                color: 'black',
                fontWeight: 500,
              }}
            >{`Creating Report${'.'.repeat(dots)}`}</Box>
            <Lottie
              animationData={require('../../assets/lotties/analyzing.json')}
              style={{
                height: 160,
                width: 160,
              }}
              loop={true}
            />
          </Stack>
        )}
      </Card>
    </>
  );
};

export default JournalSummaryCard;
