import { EditRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router';
import MedicationChip from './MedicationChip';

const MedicationCard = ({ medication, openEditMedicationDialog, variant }) => {
  const navigate = useNavigate();
  return (
    <Stack
      direction={'column'}
      justifyContent={'start'}
      alignItems={'start'}
      sx={{
        bgcolor: 'white',
        borderRadius: 2,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        p: 2,
        width: '100%',
      }}
      spacing={0}
      onClick={() =>
        variant === 'edit'
          ? openEditMedicationDialog(medication)
          : navigate('/dashboard/medications/' + medication.id)
      }
    >
      {variant === 'edit' && (
        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              fontSize: 18,
              textAlign: 'start',
              fontWeight: 700,
              flex: 1,
            }}
          >
            Medication Info
          </Typography>
          <Button
            onClick={() => openEditMedicationDialog(medication)}
            startIcon={<EditRounded />}
          >
            Edit
          </Button>
        </Stack>
      )}
      <Stack
        spacing={0}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        <Stack spacing={0}>
          <Box sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            {medication.data().medicationName}
          </Box>
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: '#5F5F5F',
              textAlign: 'start',
            }}
          >
            {medication.data().dosageAmount}mg
          </Box>
        </Stack>
        {variant !== 'edit' && (
          <IconButton onClick={() => openEditMedicationDialog(medication)}>
            <ArrowRightIcon />
          </IconButton>
        )}
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          flex: 1,
          mt: 2,
          flexFlow: 'wrap',
          gap: 1,
          justifyContent: 'start',
        }}
        // spacing={2}
      >
        <MedicationChip
          type={'timesPerDay'}
          text={`${medication.data().frequency.split('x')[0]}x Day`}
        />
        {(medication.data().frequency === '1xd' ||
          medication.data().frequency === '2xd' ||
          medication.data().frequency === '3xd') && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().firstDoseTime).format('hh:mm a')}
          />
        )}
        {(medication.data().frequency === '2xd' ||
          medication.data().frequency === '3xd') && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().secondDoseTime).format('hh:mm a')}
          />
        )}
        {medication.data().frequency === '3xd' && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().thirdDoseTime).format('hh:mm a')}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default MedicationCard;
