import { Box, Stack } from '@mui/material';
import React from 'react';

const MedicationChip = ({ type, text }) => {
  return (
    <Stack
      direction={'row'}
      spacing={1.5}
      sx={{
        bgcolor: 'white',
        textAlign: 'center',
        borderRadius: 1,
        border: '1px solid #000000',
        p: 1,
        pt: 0.5,
        pb: 0.5,
      }}
    >
      {type === 'time' ? (
        <img
          src={require('../assets/icons/time.png')}
          style={{ width: 16, height: 16 }}
        />
      ) : type === 'timesPerDay' ? (
        <img
          src={require('../assets/icons/timesPerDay.png')}
          style={{ width: 16, height: 16 }}
        />
      ) : null}
      <Box sx={{ fontSize: 12 }}>{text}</Box>
    </Stack>
  );
};

export default MedicationChip;
