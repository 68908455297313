import { Card, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../../contexts/Profile';
import useResponsive from '../../hooks/useResponsive';
import { PostSurveyDialog } from '../SurveyCard';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';

const AssesmentCard = ({ event, list = false, type }) => {
  const isDesktop = useResponsive('up', 'md');

  const { role } = useContext(ProfileContext);

  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [openPostSurveyDialog, setOpenPostSurveyDialog] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <PostSurveyDialog
        open={openPostSurveyDialog}
        setOpen={setOpenPostSurveyDialog}
        event={event || null}
      />
      <Card
        sx={{
          width: list ? '100%' : isDesktop ? 327 : '100%',
          minHeight: 0,
          p: 2,
          mr: list ? 0 : 0,
          cursor: 'pointer',
          borderRadius: 1,
          position: 'relative',
        }}
        elevation={4}
      >
        <Stack
          direction={'column'}
          spacing={2}
          alignItems={'center'}
          justifyContent={'space-between'}
          style={{ height: '100%' }}
        >
          <CardHeader event={event} type={type} />
          <div style={{ flexGrow: 1 }}></div>
          <CardFooter event={event} type={type} />
        </Stack>
      </Card>
    </>
  );
};

export default AssesmentCard;
