import {
  Box,
  Container,
  Divider,
  Slide,
  Stack,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import JournalItems from '../components/JournalItems';
import { AuthContext } from '../contexts/Auth';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import { WalkthroughContext } from '../contexts/Walkthrough';
import useResponsive from '../hooks/useResponsive';

export function ShowOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return (
    <Slide appear={false} direction="up" in={trigger}>
      {children}
    </Slide>
  );
}

export function UnhideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  return trigger ? children : <></>;
}

export function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: window ? window.screen.height - 100 : 400,
  });

  if (trigger) {
    return <></>;
  }
  return children;
}

const Timeline = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { showSuccessAndContactDialog, setShowSuccessAndContactDialog } =
    useContext(AuthContext);
  const { currentProfile, role, customEvents, organization, medications } =
    useContext(ProfileContext);
  const { events, setEventDialogIsOpen, openEventDialog } =
    useContext(EventsContext);
  const isDesktop = useResponsive('up', 'md');
  const [medicationMessages, setMedicationMessages] = useState([]);
  const { run } = useContext(WalkthroughContext);

  const countEventsFromStartOfMonth = () => {
    if (!events) return '-';
    // Get the current date
    const currentDate = moment();

    // Get the start of the current month
    const startOfMonth = currentDate.startOf('month');

    // Filter events created from the start of the current month
    const eventsFromStartOfMonth = events.filter((event) => {
      const eventDate = moment(event.data().submittedAt.seconds * 1000);
      return eventDate.isSameOrAfter(startOfMonth);
    });

    // Return the count of events
    return eventsFromStartOfMonth.length;
  };

  useEffect(() => {
    if (medications) {
      const checkMedicationTimes = () => {
        const now = dayjs();
        const oneHourAgo = now.subtract(1, 'hour');

        const medicationMessages = medications
          .map((medication) => {
            const data = medication.data(); // Extract medication data
            const {
              medicationName,
              firstDoseTime,
              secondDoseTime,
              thirdDoseTime,
            } = data;

            // Convert dose times to Day.js objects for comparison
            const doseTimes = [
              dayjs(firstDoseTime).format('HH:mm'),
              dayjs(secondDoseTime).format('HH:mm'),
              dayjs(thirdDoseTime).format('HH:mm'),
            ]
              .filter(Boolean) // Remove undefined/null values
              .map((time) => dayjs(time));

            // Check if any dose time falls within the last hour
            const isTimeToTakeMedication = doseTimes.some(
              (time) => time.isAfter(oneHourAgo) && time.isBefore(now),
            );

            if (!isTimeToTakeMedication) {
              return {
                id: medication.id,
                name: medicationName,
              };
            }

            return null; // Return null if it's not time
          })
          .filter(Boolean); // Remove null values

        console.log(medicationMessages);
        // You can further use medicationMessages for UI or notifications
        setMedicationMessages(medicationMessages);
      };

      checkMedicationTimes();
    }
  }, [medications]);

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        sx={{
          pt: 0,
          borderRadius: 16,
        }}
      >
        <Stack
          direction={'column'}
          gap={2}
          sx={{
            width: '100%',
            pl: 2,
            pr: 2,
            pt: 3,
            pb: 3,
            position: 'relative',
            bgcolor: '#FDF4F3',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: 400 }}>
            <Stack
              direction={'row'}
              sx={{
                bgcolor: '#CFF5E3',
                p: 1,
                borderRadius: 2,
                zIndex: 0,
                position: 'relative',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              }}
              justifyContent={'space-around'}
            >
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ mr: -5 }}
                spacing={1}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
                >
                  {countEventsFromStartOfMonth()}
                </Typography>
                <Stack direction={'column'} alignItems={'start'} sx={{}}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                  >
                    {moment().format('MMM')}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: '#0C3954',
                      mt: -1,
                    }}
                  >
                    Entries
                  </Typography>
                </Stack>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ ml: -5 }}
                spacing={1}
              >
                <Typography
                  sx={{ fontWeight: 600, fontSize: 36, color: '#0C3954' }}
                >
                  {events.length}
                </Typography>
                <Stack direction={'column'} alignItems={'start'} sx={{}}>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 16, color: '#0C3954' }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      color: '#0C3954',
                      mt: -1,
                    }}
                  >
                    Entries
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <JournalItems events={events} />
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Timeline;
