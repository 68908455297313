import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext } from 'react';

export const SnackbarContext = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarContextProvider(props) {
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [type, setType] = React.useState('');
  const [message, setMessage] = React.useState('');

  const show = (message, type = 'info') => {
    setType(type);
    setMessage(message);
    setShowSnackbar(true);
  };

  const hide = () => {
    setShowSnackbar(false);
    setType('');
  };

  return (
    <SnackbarContext.Provider
      value={{
        show: show,
        hide: hide,
        message: message,
        type: type,
        showSnackbar: showSnackbar,
      }}
    >
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={hide}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 9999 }}
      >
        <Alert onClose={hide} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <div>{props.children}</div>
    </SnackbarContext.Provider>
  );
}

export default SnackbarContextProvider;
