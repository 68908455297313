import { Add, CheckRounded, CloseRounded } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ProfileContext } from '../contexts/Profile';
import { SignupContext } from '../contexts/Signup';
import AddEditMedication from './AddEditMedication';
import MedicationCard from './MedicationCard';

const ManageMedications = () => {
  const navigate = useNavigate();

  const { medications } = React.useContext(ProfileContext);
  const { afterSignup } = React.useContext(SignupContext);
  const [openAddEditMedicationDialog, setOpenAddEditMedicationDialog] =
    React.useState(false);
  const [medication, setMedication] = React.useState(null);

  const openEditMedicationDialog = (medication) => {
    setOpenAddEditMedicationDialog(true);
    setMedication(medication);
  };

  useEffect(() => {
    if (!openAddEditMedicationDialog) {
      setMedication(null);
    }
  }, [openAddEditMedicationDialog]);

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{ width: '100%', minHeight: 'calc(100vh - 110px)', flexGrow: 1 }}
    >
      {openAddEditMedicationDialog && (
        <AddEditMedication
          open={openAddEditMedicationDialog}
          setOpen={setOpenAddEditMedicationDialog}
          medication={medication}
        />
      )}
      {medications?.length === 0 && (
        <Stack
          sx={{ width: '100%', mt: 6 }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
        >
          <Typography sx={{ fontWeight: '600', fontSize: 24 }}>
            Add medications
          </Typography>

          <Lottie
            animationData={require('../assets/lotties/medication-blank-state.json')}
            style={{
              height: 220,
              width: 220,
            }}
            loop={true}
          />
          <Typography sx={{ fontSize: 24 }}>
            Get notified daily with reminders to take your medications
          </Typography>
        </Stack>
      )}
      <Stack
        spacing={0}
        rowGap={2}
        direction={'column'}
        sx={{ flexWrap: 'wrap', mt: 2, width: '100%' }}
        justifyContent={'start'}
      >
        {[...medications].map((medication, _index) => (
          <MedicationCard
            key={_index}
            medication={medication}
            openEditMedicationDialog={openEditMedicationDialog}
          />
        ))}
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant="contained"
        onClick={() => setOpenAddEditMedicationDialog(true)}
        fullWidth
        size="large"
        startIcon={<Add />}
      >
        Add medications
      </Button>
      {afterSignup && (
        <Button
          variant="contained"
          onClick={() => navigate('/dashboard/share-profile')}
          fullWidth
          size="large"
          sx={
            medications?.length > 0
              ? {}
              : {
                  'bgcolor': '#AAAAAA',
                  ':focus': {
                    bgcolor: '#AAAAAA',
                  },
                  ':hover': {
                    bgcolor: '#AAAAAA',
                  },
                }
          }
        >
          {medications?.length > 0 ? 'Next' : 'Skip for Now'}
        </Button>
      )}
    </Stack>
  );
};

const MedicationLogs = ({ year, month } = { year: '25', month: '01' }) => {
  // Get the start and end of the month
  const startOfMonth = dayjs(`${year}-${month}-01`);
  const endOfMonth = startOfMonth.endOf('month');

  const today = dayjs();

  // Get the start of the week for the first day of the month (Monday)
  const startOfGrid = startOfMonth.startOf('week');

  // Get the end of the week for the last day of the month (Sunday)
  const endOfGrid = endOfMonth.endOf('week');

  // Generate an array of weeks with their days
  const weeks = [];
  let currentDay = startOfGrid;

  while (currentDay.isBefore(endOfGrid, 'day')) {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDay);
      currentDay = currentDay.add(1, 'day');
    }
    weeks.push(week);
  }

  return (
    <Box sx={{ padding: 0, width: '100%' }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'start' }}>
        {/* {startOfMonth.format('MMMM YYYY')} */}
        Medication Logs
      </Typography>
      <Typography gutterBottom sx={{ textAlign: 'start', mb: 1 }}>
        Medications taken this month
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        {/* Render day labels (Monday to Sunday) */}
        {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
          <Grid item xs={1.71} key={index}>
            <Typography sx={{ fontSize: 12 }} align="center">
              {day}
            </Typography>
          </Grid>
        ))}
        {/* Render weeks */}
        {/* Render weeks */}
        {weeks.map((week, weekIndex) => (
          <Grid container item spacing={1} key={weekIndex}>
            {week.map((day, dayIndex) => (
              <Grid item xs={1.71} key={dayIndex}>
                <Box
                  sx={{
                    // border: '1px solid #ccc',
                    borderRadius: '50%',
                    padding: '10%',
                    textAlign: 'center',
                    backgroundColor: day.isSame(startOfMonth, 'month')
                      ? dayIndex % 2 === 0
                        ? '#76E6B8'
                        : '#EF97AD'
                      : '#f5f5f5',
                    aspectRatio: '1',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                  }}
                >
                  {!day.isSame(startOfMonth, 'month') ? (
                    <></>
                  ) : dayIndex % 2 === 0 ? (
                    <CheckRounded sx={{ width: '18px', height: '18px' }} />
                  ) : (
                    <CloseRounded sx={{ width: '18px', height: '18px' }} />
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ManageMedications;
