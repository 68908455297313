import {
  ArrowBack,
  ArrowForward,
  CheckRounded,
  CloseRounded,
} from '@mui/icons-material';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { SnackbarContext } from '../contexts/Snackbar';
import firebase from '../Firebase';

const MedicationLogs = ({ medication, initialYear, initialMonth }) => {
  const [medicationLogs, setMedicationLogs] = React.useState([]);
  const { show } = React.useContext(SnackbarContext);

  // Use a state for the current month (defaulting to the provided initial values or today)
  const [currentMonth, setCurrentMonth] = React.useState(
    initialYear && initialMonth
      ? dayjs(`${initialYear}-${initialMonth}-01`)
      : dayjs(),
  );

  const startOfMonth = currentMonth.startOf('month');
  const endOfMonth = currentMonth.endOf('month');
  const today = dayjs();

  // Calculate the grid start (start of the week) and grid end (end of the week)
  const startOfGrid = startOfMonth.startOf('week');
  const endOfGrid = endOfMonth.endOf('week');

  // Generate a 2D array of dayjs objects (weeks containing days)
  const weeks = [];
  let currentDay = startOfGrid;
  while (
    currentDay.isBefore(endOfGrid, 'day') ||
    currentDay.isSame(endOfGrid, 'day')
  ) {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDay);
      currentDay = currentDay.add(1, 'day');
    }
    weeks.push(week);
  }

  // Given a day (a dayjs object), check if a log exists
  const getMedicationStatusForDay = (day) => {
    if (!medicationLogs.length) return null;
    const logEntry = medicationLogs.find((log) =>
      dayjs(log.date.seconds * 1000).isSame(day, 'day'),
    );
    return logEntry ? logEntry.taken : null;
  };

  // Toggle medication status on a given day (only if not in the future)
  const markMedicationAsTaken = (day, currentStatus) => {
    if (day.isAfter(today, 'day')) return; // do nothing for future days

    // Toggle: if already true, then mark as false; otherwise mark as true.
    const newStatus = currentStatus === true ? false : true;
    const docId = day.format('YYYY-MM-DD');

    firebase
      .firestore()
      .collection('medications')
      .doc(medication.id)
      .collection('medicationLogs')
      .doc(docId)
      .set(
        {
          date: firebase.firestore.Timestamp.fromMillis(
            day.startOf('day').valueOf(),
          ),
          taken: newStatus,
        },
        { merge: true },
      )
      .then(() => {
        show('Medication status updated successfully');
      })
      .catch((error) => console.error('Error updating document: ', error));
  };

  // Subscribe to medication logs (and unsubscribe on unmount)
  React.useEffect(() => {
    if (medication) {
      const unsubscribe = firebase
        .firestore()
        .collection('medications')
        .doc(medication.id)
        .collection('medicationLogs')
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => data.push(doc.data()));
          setMedicationLogs(data);
        });
      return () => unsubscribe();
    }
  }, [medication]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        bgcolor: 'white',
        p: 2,
        borderRadius: 2,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        sx={{ width: '100%' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            fontSize: 18,
            textAlign: 'start',
            fontWeight: 700,
            flex: 1,
          }}
        >
          Monthly progress
        </Typography>
        {/* Month Navigation Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'end'}
          sx={{ flex: 1 }}
        >
          <IconButton
            onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month'))}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            sx={{
              fontSize: 18,
            }}
          >
            {currentMonth.format('MMM YY')}
          </Typography>
          <IconButton
            onClick={() => setCurrentMonth(currentMonth.add(1, 'month'))}
          >
            <ArrowForward />
          </IconButton>
        </Stack>
      </Stack>

      <Grid container spacing={1} justifyContent="center">
        {/* Render day labels (Monday to Sunday) */}
        {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((dayLabel, idx) => (
          <Grid item xs key={idx}>
            <Typography align="center" sx={{ fontSize: 12 }}>
              {dayLabel}
            </Typography>
          </Grid>
        ))}

        {/* Render the weeks */}
        {weeks.map((week, weekIndex) => (
          <Grid container item spacing={1} key={weekIndex}>
            {week.map((day, dayIndex) => {
              const status = getMedicationStatusForDay(day);
              const isFutureDay = day.isAfter(today, 'day');
              const isPast = day.isBefore(today, 'day');
              const isCurrentMonth = day.isSame(currentMonth, 'month');

              // Default background for days in the current month (alternating) or out-of-month days
              let computedBgColor = isCurrentMonth ? '#f5f5f5' : 'lightgray';
              let computedIcon = null;

              if (isPast) {
                if (status === true) {
                  computedBgColor = isCurrentMonth
                    ? 'primary.main'
                    : 'lightgray';
                  computedIcon = (
                    <CheckRounded sx={{ width: '18px', height: '18px' }} />
                  );
                } else if (status === false) {
                  computedBgColor = isCurrentMonth
                    ? 'secondary.main'
                    : 'lightgray';
                  computedIcon = (
                    <CloseRounded sx={{ width: '18px', height: '18px' }} />
                  );
                } else {
                  // For past day with no log, show "x" by default
                  computedBgColor = isCurrentMonth
                    ? 'secondary.main'
                    : 'lightgray';
                  computedIcon = (
                    <Typography variant="caption" sx={{ color: 'black' }}>
                      <CloseRounded sx={{ width: '18px', height: '18px' }} />
                    </Typography>
                  );
                }
              } else {
                // For today or future days
                if (status === true) {
                  computedBgColor = 'primary.main';
                  computedIcon = (
                    <CheckRounded sx={{ width: '18px', height: '18px' }} />
                  );
                } else if (status === false) {
                  // Explicitly not taken (if set) – you can adjust the bg color if desired.
                  computedIcon = (
                    <CloseRounded sx={{ width: '18px', height: '18px' }} />
                  );
                }
                // If no value, leave computedIcon as null.
              }

              return (
                <Grid item xs key={dayIndex}>
                  <Stack spacing={0.5} alignItems="center">
                    <Box
                      onClick={() =>
                        !isFutureDay && markMedicationAsTaken(day, status)
                      }
                      sx={{
                        cursor: isFutureDay ? 'default' : 'pointer',
                        borderRadius: '50%',
                        p: '10%',
                        textAlign: 'center',
                        backgroundColor: computedBgColor,
                        aspectRatio: '1',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!isFutureDay && computedIcon}
                    </Box>
                    <Typography variant="caption">{day.format('D')}</Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default MedicationLogs;
